<template>
  <div class="conditions-page">
    <ConditionsComponent/>
  </div>
</template>


<script>
import ConditionsComponent from "@/components/ConditionsComponent.vue";

export default {
  name: "ConditionsPage",
  components: {
    ConditionsComponent
  }
};
</script>

<style>
.conditions-page {
  background: #E1F2F9;
}
</style>