





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

@Component
export default class ConditionsComponent extends Vue {
  conditionItems = [
    {
      title: this.$t(
        "infoContent.conditionsItems.firstItem.title"
      ) as TranslateResult,
      text: this.$t(
        "infoContent.conditionsItems.firstItem.text"
      ) as TranslateResult
    },
    {
      title: this.$t(
        "infoContent.conditionsItems.secondItem.title"
      ) as TranslateResult,
      text: this.$t(
        "infoContent.conditionsItems.secondItem.text"
      ) as TranslateResult
    },
    {
      title: this.$t(
        "infoContent.conditionsItems.thirdItem.title"
      ) as TranslateResult,
      text: this.$t(
        "infoContent.conditionsItems.thirdItem.text"
      ) as TranslateResult
    },
    {
      title: this.$t(
        "infoContent.conditionsItems.fourthItem.title"
      ) as TranslateResult,
      text: this.$t(
        "infoContent.conditionsItems.fourthItem.text"
      ) as TranslateResult
    },
    {
      title: this.$t(
        "infoContent.conditionsItems.fifthItem.title"
      ) as TranslateResult,
      text: this.$t(
        "infoContent.conditionsItems.fifthItem.text"
      ) as TranslateResult
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.conditionItems = [
      {
        title: this.$t(
          "infoContent.conditionsItems.firstItem.title"
        ) as TranslateResult,
        text: this.$t(
          "infoContent.conditionsItems.firstItem.text"
        ) as TranslateResult
      },
      {
        title: this.$t(
          "infoContent.conditionsItems.secondItem.title"
        ) as TranslateResult,
        text: this.$t(
          "infoContent.conditionsItems.secondItem.text"
        ) as TranslateResult
      },
      {
        title: this.$t(
          "infoContent.conditionsItems.thirdItem.title"
        ) as TranslateResult,
        text: this.$t(
          "infoContent.conditionsItems.thirdItem.text"
        ) as TranslateResult
      },
      {
        title: this.$t(
          "infoContent.conditionsItems.fourthItem.title"
        ) as TranslateResult,
        text: this.$t(
          "infoContent.conditionsItems.fourthItem.text"
        ) as TranslateResult
      },
      {
        title: this.$t(
          "infoContent.conditionsItems.fifthItem.title"
        ) as TranslateResult,
        text: this.$t(
          "infoContent.conditionsItems.fifthItem.text"
        ) as TranslateResult
      }
    ];
  }
}
